// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-blog-post-blog-post-jsx": () => import("./../../../src/templates/blog-post/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-jsx" */),
  "component---src-templates-contributions-contributions-jsx": () => import("./../../../src/templates/contributions/contributions.jsx" /* webpackChunkName: "component---src-templates-contributions-contributions-jsx" */),
  "component---src-templates-home-home-jsx": () => import("./../../../src/templates/home/home.jsx" /* webpackChunkName: "component---src-templates-home-home-jsx" */),
  "component---src-templates-post-list-post-list-jsx": () => import("./../../../src/templates/post-list/post-list.jsx" /* webpackChunkName: "component---src-templates-post-list-post-list-jsx" */)
}

